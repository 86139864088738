import * as React from "react"
import CommonProduct, {toggleCtrl,headingLookup} from "../components/commonPage"
import {Backdrop,CircularProgress,Link, Radio, RadioGroup,TextField,  Divider, FormGroup, FormControlLabel,Checkbox,Chip,Button, Stack , Box} from '@mui/material'
import { navigate } from "gatsby"
import LoadProducts from "../components/loadProducts"
import {exportList, exportCSV} from "../components/exportList"
import createXLSX from "../components/exportXLSX"
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import {styled} from '@mui/material/styles'


var _ = require('underscore')
import $ from 'jquery';

export interface LandingProps {
  productFieldOrderMeta: any,
}

const excluded_fields = {
  supportedLanguage: true,
}

const order_by = {'relevance':"Relevance",'lastModifiedDate':"Last Modified",'name': "Name - Alphabetical"}

const StyledInputElement = styled('input')`
  width: 200px;
  font-size: 1rem;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  background: rgb(243, 246, 249);
  border: 1px solid #e5e8ec;
  border-radius: 10px;
  padding: 6px 10px;
  color: #20262d;
  transition: width 300ms ease;

  &:hover {
    background: #eaeef3;
    border-color: #e5e8ec;
  }

  &:focus {
    outline: none;
    width: 220px;
    transition: width 200ms ease-out;
  }
`;


class MyListPage extends CommonProduct {

  isBrowser: boolean
  exportEmailAddress: string
  exportChoice: string
  firstSearch: boolean = true
  constructor(props)
  {
    super(props)
    this.state.my_focus = 0 // changed to 0 for user wanted None for focus initially
    this.state.exportMenu=false
    this.state.showExportMenu = false
    this.state.compareProds = []
    this.firstRender = true

    this.exportEmailAddress=''
    this.exportChoice=null
    const {my_product_keys,my_product_lookup} = this.getFromLocalstorage('my_list')
    this.state.my_product_keys = my_product_keys
    this.state.my_product_lookup = my_product_lookup
    // this.state.showBackdropLoading=false
  }




  getProductsUnderComparisonOnly(products){

    const compare_keys_selected = this.getFromLocalstorage('compare').my_product_keys
    const filtered_products = _.filter(products, (p) => (  compare_keys_selected.includes( p.productKey.toString()) ) )

    return filtered_products
  }
  
  modeOfExport =() => {
    const menu=document.querySelector<HTMLElement>('#popup_window .header + div')
    if (!!menu){
      menu.style.visibility = 'visible'
    }
    const loader= document.getElementById('loader')
    if (!!loader){
      loader.style.display = 'none'
    }
  }
  
  
  modeOfLoader =() => {
    const menu=document.querySelector<HTMLElement>('#popup_window .header + div')
    if (!!menu){
      menu.style.visibility = 'hidden'
    }
    
    const loader= document.getElementById('loader')
    if (!!loader){
      loader.style.display = 'block'
    }
  }

  loadCompareProducts(products)
  {
      this.setState({compareProds:products})
      window.sessionStorage['lastLoadedProducts'] = JSON.stringify(products)  // Need sessionStorage.lastLoadedProducts to pass to compare.tsx because localStorage.lastLoadedProducts will cause CORS issue
  }

  InterstitialContent(products:any) {
    let itemCountPdf = products?.length??0

    // const compare_keys_selected = this.getFromLocalstorage('compare').my_product_keys
    const compare_keys_selected_count = this.getFromLocalstorage('compare').my_product_keys.length

    if (!!document.getElementById("loader"))
      {document.getElementById("loader").style.display="none"}
      
    return (

  
      <Stack>
        
        <Box sx={{ height: 50}}/>

        <Box sx={{ml:5, mr:5, fontFamily:'Montserrat',fontSize:18}}>You can export your entire shortlist of products or download your current selected comparison.</Box>
        <Box sx={{ height: 60}}/>
        <FormGroup sx={{ml:5}}>
          <RadioGroup sx={{ml:5, mr:5, fontFamily:'Montserrat',fontSize:14}} aria-label="exportChoice" name="row-radio-buttons-group">
          <FormControlLabel value="preventNoSelection" control={<Radio  sx={{display: 'none'}}  size="small" disableRipple={true}  color="primary" />}  label={""} />

            <FormControlLabel value="radioExportPDF" control={<Radio  onChange={this.handleChangeRadio}  size="small" disableRipple={true}  color="primary" />}  label={"Export my whole list as a PDF ("+itemCountPdf+" items)"} />
            <FormControlLabel value="radioExportCSV1"  control={<Radio  onChange={this.handleChangeRadio}  size="small" disableRipple={true}  color="primary" />} label={"Export latest comparison as a CSV file ("+compare_keys_selected_count+" items)"}  />
            <Box>
              <FormControlLabel sx={{ml:2}} value="radioExportXLSX"  control={<Radio   onChange={this.handleChangeRadio} size="small" disableRipple={true}  color="primary" />} label="Export latest comparison with Decision Rubric as an Excel file (up to 10 items)" />
              <Link  sx={{color:'017961' , fontFamily:'Montserrat',fontSize:15, fontWeight:400}}  href="https://docs.google.com/spreadsheets/d/1i5NRQGL6r8sFWFdyiBsXJY0p5UMQUe5CdAEg2nVht2M/edit#gid=591365272" rel="noopener noreferrer" target="_blank" >What does it look like?</Link>
            </Box>            

          </RadioGroup>
        </FormGroup>
        <Box sx={{ height: 90}}/>
        <Divider></Divider>
        <Box sx={{ height: 20}}/>

        <Stack direction="row">
  
        <Button className= "template template-export-button"  id="export_download" onClick={ this.exportDownload(products,this) }><Box id="box_download_label"> Download <div className="right_arrow"/></Box> </Button>
        </Stack>
        <Box sx={{ height: 50}}/>
      </Stack>
  
  
  
    );
  }
  
  exportDownload(products:any,parent:any) {
    return async _ => {
      let productsToExport = parent.state.products;

      this.modeOfLoader()
      switch (parent.exportChoice){

        case "radioExportCSV1":
          exportCSV(this.getProductsUnderComparisonOnly(products));
          break;
        case "radioExportXLSX":
          // needs more than products?
          const productsForXLSX=this.getProductsUnderComparisonOnly(products)
          createXLSX(productsForXLSX);
          break;
        case "radioExportPDF":
          await exportList(productsToExport);
          break;
      }
      
      // cleaning exportChoice
      parent.exportChoice=null
      
      this.modeOfExport()
      return true
      
    }
  }

  handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.exportChoice = event.target.value
  };


onChangeEmailTextField(parent:any) {
  return(event:React.ChangeEvent<HTMLInputElement>) => {
    this.exportEmailAddress = event.target.value
  }
}


  exportMenu(products) {  // making an interstitial
    if (!(this.state.showExportMenu) )
    {
      return null
    }

    this.modeOfExport()
  
    return <>
      <div id="popup">
      </div>
      <div id="popup_holder">
      <div id="popup_window">

      <div className="header">Export My List<i onClick={()=>this.setState({showExportMenu:false})} className="fal fa-times"></i></div>
      {this.InterstitialContent(products)}

        
      </div>  <div id="loader"/>
      </div>
    </>
        
  }

  componentDidUpdate()
  {
    $('.product p').each((index, desc)=>{
      let floorHeight = Math.floor($(desc).height())
      if (floorHeight== $(desc)[0].scrollHeight)
      {
        $($(desc).parent()).addClass('noMore')
      }
    })
  }


  render() {
    let {mv_lookups,compareProds:products,my_product_keys} = this.state

    let loadCompareProducts = this.loadCompareProducts.bind(this)

    let filterLookup = {}

    let headingKeys = Object.keys(headingLookup)
    if (this.state.my_focus!=null && !!mv_lookups.products)
    {
      let focus = this.state.my_focus-1
      let choice = headingKeys[focus]
      let fieldLookup = this.filterTree[choice]
      if (fieldLookup)
      {
        for (let fieldName of Object.keys(fieldLookup) )
        {
          _.extendOwn(filterLookup, mv_lookups.products[fieldName])
        }
      }
    }


    let BaseComponent = this.BaseComponentRender.bind(this)
    let selectAllToCompare = this.selectAllToCompare.bind(this)

    let allSelected = true
    let selectedCount = 0
    for (let product_id of this.state.my_product_keys)
    {
        let product_key = 'compare_'+product_id
        if (!this.state[product_key])
        {
          allSelected = false
        }
        else
        {
          selectedCount++
        }
    }

    if (allSelected)
    {
      selectAllToCompare = this.deselectAllToCompare.bind(this)
    }

    let focusHeading = 'None'
    if (this.state.my_focus>0)
    {
      focusHeading = headingLookup[headingKeys[this.state.my_focus-1]]      
    }
    let focusChoices = _.extend([],headingKeys)
    focusChoices.unshift('None')

    return (
      <BaseComponent controller={this} menu="my_list" title="My List | EdTech Index" my_product_keys={my_product_keys}>
      <h1><strong>My List</strong> ({my_product_keys.length})</h1>
      {this.exportMenu(products)}
      <Button className="template" id="export_list_button" onClick={ ()=> this.setState({showExportMenu: true}) }><Box className="exportListBox" >Export List <div className="right_arrow"/></Box></Button>  
      {

      }
      <LoadProducts 
              onChange={loadCompareProducts}
              state={this.state}
              reset={this.firstSearch}
              recordIds={my_product_keys}
              initState={'initCompare'}
              ></LoadProducts>

      <div id="product_main">
        <div className="dividingLine"/>
        {
          !this.isTablet &&
          <>
            <span className="focus_on">Focus on results</span>
                <DropdownButton className="dropdown" title={focusHeading}>
                  {
                    focusChoices.map((fieldName, index)=>{
                      let option = 'None'
                      if (index>0)
                      {
                        option = headingLookup[fieldName]
                      }
                      let bound_values:any = {unique_id:'my_focus',state:index,controller:this,save_group:'focus'}
                      let toggler:toggleCtrl = new toggleCtrl(bound_values)
                      let clickFunction = toggler.changeSelection.bind(toggler)
                      return <Dropdown.Item key={"focus_"+fieldName} onClick={clickFunction} >{option}</Dropdown.Item>
                    })
                  }
                </DropdownButton>
          </>
        }
        <FormControlLabel
                      label={((allSelected)?'Des':'S')+"elect All"}
                      className="selAll"
                      disabled={(products.length>10)}
                      control={<Checkbox size="small" disableRipple={true} checked={allSelected} onClick={selectAllToCompare} color="primary" />}
                      />
        
        <Button className={(selectedCount>1)?"compareSel":"compareSel disabled"} onClick={()=>{if (selectedCount>1) navigate("/compare/")}}>Compare Selected {(selectedCount>1)?('('+selectedCount+')'):''}</Button>

        {
          this.isTablet &&
          <>
            <span className="focus_on">Focus on</span>
                    <DropdownButton className="dropdown" title={focusHeading}>
                      {
                        focusChoices.map((fieldName, index)=>{
                          let option = 'None'
                          if (index>0)
                          {
                            option = headingLookup[fieldName]
                          }
                          let bound_values:any = {unique_id:'my_focus',state:index,controller:this,save_group:'focus'}
                          let toggler:toggleCtrl = new toggleCtrl(bound_values)
                          let clickFunction = toggler.changeSelection.bind(toggler)
                          return <Dropdown.Item key={"focus_"+fieldName} onClick={clickFunction} >{option}</Dropdown.Item>
                        })
                      }
                    </DropdownButton>
          </>
        }

          {
            (products.length>0) &&
            products.map( (product,index) => {
              let product_key = 'product_'+product.productKey
              let my_list_state = this.state[product_key]
              let my_new_state = (!!my_list_state)?null:product.name
              let updateMyList = this.updateMyList.bind(this)
              let updateCompareList = this.updateCompareList.bind(this)
              let bound_values:any = {unique_id:product_key,state:my_new_state,controller:this,save_group:'my_list',onUpdate:updateMyList}
              let toggler:toggleCtrl = new toggleCtrl(bound_values)
              let clickFunction = toggler.changeSelection.bind(toggler)

              let compare_key = 'compare_'+product.productKey
              let my_compare_state = !!this.state[compare_key]
              bound_values = {unique_id:compare_key,state:my_compare_state,controller:this,save_group:'compare',onUpdate:()=>{updateCompareList(!my_compare_state)}}
              toggler = new toggleCtrl(bound_values)
              let clickCompareFunction = toggler.toggleSelection.bind(toggler)

              let moreDescId = product_key+'_morelink'
  
              return <div className="product" key={'productwrap_'+product.productKey} id={moreDescId}>
                {this.state.compare_maxed}
                  <FormControlLabel
                      className="compare_sel"
                      label=""
                      control={<Checkbox size="small" disabled={this.state.compare_maxed && !my_compare_state} disableRipple={true} checked={my_compare_state} onClick={clickCompareFunction} color="primary" />}
                      />
                  <div className="imgHolder"><img src={product.image_url} /></div>
                  <div className="listButton">
                    <Button className={my_list_state?'selected':'deselected'} onClick={clickFunction} variant="outlined">{my_list_state?'Added':'Add to my list'}</Button>
                  </div>
                  <strong onClick={()=>{navigate((!!product.ultid)?"/product/?ultid="+product.ultid:"/product/?recordId="+product.productKey)}}>{product.name}</strong>
                  by {product.organizationName}<br/>
                  {
                    product.mv_items.map((filterKey,fIndx) => {
                      if (filterLookup[filterKey])
                      {
                        let filterName = filterLookup[filterKey]

                        return <Chip key={product.productKey+'_'+filterName} id={product.productKey+'_'+filterName} label={filterName}/>
                      }
                    })
                  }
                  
                  <p className="desc">{product.publicDescription}</p>
                  <div className="moreLink" onClick={()=>{$('#'+moreDescId).toggleClass('more')}}><span className="down_arrow"></span><span className="up_arrow"></span></div>
                </div>
            } )
          }
      </div>
      </BaseComponent>
    )
  }
}

export default MyListPage
